import ReactFlow, { Background, Controls } from 'react-flow-renderer';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';


const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
  
    useEffect(() => {
      fetch('/api/blogs')
        .then(response => response.json())
        .then(data => setBlogs(data));
    }, []);
  
    return (
      <div className="container">
        <h1 className="text-3xl font-bold mb-4">My Blog Posts</h1>
        <ul className="space-y-4">
          {blogs.map(blog => (
            <li key={blog.id} className="bg-gray rounded shadow overflow-hidden">
              {blog.headerImage && blog.type !== 'canvas' && (
                <img src={blog.headerImage} alt={blog.title} className="w-full h-48 object-cover" />
              )}
              {blog.type === 'canvas' && (
                <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-600">Canvas Preview</span>
                </div>
              )}
              <div className="p-4">
                <Link to={`/blogs/${blog.id}`} className="text-white hover:text-blue-800 font-semibold">
                  {blog.title}
                </Link>
                <div className="text-sm text-gray-500 mt-1">
                  {new Date(blog.date).toLocaleDateString()}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  const BlogPost = () => {
    const [post, setPost] = useState(null);
    const { id } = useParams();
  
    useEffect(() => {
      fetch(`/api/blogs/${id}`)
        .then(response => response.json())
        .then(data => setPost(data));
    }, [id]);
  
    if (!post) return <div className="text-center">Loading...</div>;
  
    const renderCanvas = () => {
      if (post.type !== 'canvas') return null;
  
      try {
        const canvasData = JSON.parse(post.content.replace(/&quot;/g, '"'));
        
        const elements = [
          ...canvasData.nodes.map(node => ({
            id: node.id,
            type: 'default',
            data: { label: node.text },
            position: { x: node.x, y: node.y },
            style: { width: node.width, height: node.height }
          })),
          ...canvasData.edges.map(edge => ({
            id: edge.id,
            source: edge.fromNode,
            target: edge.toNode,
            type: 'smoothstep'
          }))
        ];
  
        return (
          <div style={{ height: '600px', width: '100%' }}>
            <ReactFlow 
              elements={elements}
              nodesDraggable={false}
              nodesConnectable={false}
              elementsSelectable={false}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        );
      } catch (error) {
        console.error('Error parsing canvas data:', error);
        return <div>Error rendering canvas content</div>;
      }
    };
  
    return (
      <div className="container">
        <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
        <div className="text-sm text-gray-500 mb-4">
          Published on {new Date(post.date).toLocaleDateString()}
        </div>
        {post.type === 'markdown' && post.headerImage && (
          <img src={post.headerImage} alt={post.title} className="w-full mb-4 rounded" />
        )}
        {post.type === 'markdown' && (
          <div className="prose blog-text" dangerouslySetInnerHTML={{ __html: post.content }} />
        )}
        {post.type === 'canvas' && renderCanvas()}
      </div>
    );
  };

  export {BlogPost, Blogs}