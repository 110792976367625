import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';


const Projects = () => {
    const [projects, setProjects] = useState([]);
  
    useEffect(() => {
      fetch('/api/projects')
        .then(response => response.json())
        .then(data => setProjects(data));
    }, []);
  
    return (
      <div className="container">
        <h1 className="text-3xl font-bold mb-4">My Projects</h1>
        <ul className="space-y-2">
          {projects.map(project => (
            <li key={project.id} className="bg-gray p-4 rounded shadow">
              <Link to={`/projects/${project.id}`} className="text-white hover:text-blue-800 font-semibold">
                {project.title}
              </Link>
              <span className="ml-2 text-sm text-gray-500">{new Date(project.date).toLocaleDateString()}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  
const ProjectDetails = () => {
    const [project, setProject] = useState(null);
    const { id } = useParams();
  
    useEffect(() => {
      fetch(`/api/projects/${id}`)
        .then(response => response.json())
        .then(data => setProject(data));
    }, [id]);
  
    if (!project) return <div className="text-center">Loading...</div>;
  
    return (
      <div className="container">
        <h1 className="text-3xl font-bold mb-4">{project.title}</h1>
        <div className="prose blog-text" dangerouslySetInnerHTML={{ __html: project.content }} />
      </div>
    );
  };
  
  export {Projects, ProjectDetails};