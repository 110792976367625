import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import './App.css';
import {Blogs, BlogPost} from './components/Blogs'
import { Projects, ProjectDetails } from './components/Projects';


const Home = () => (
  <div>
    <div className="image-container">
      <img src="/images/blogs/sky.jpg" alt="Journey of a Data Engineer" className="full-width-top rounded shadow"/>
    </div>
    <div className="container">
      <div className="header">
        <h1 className="font-bold mb-4">Journey of a Data Engineer</h1>
      </div>
      <p className="text">Data Engineering</p>
      <p className="text">
        My journey in the tech world began with me following my intuition and just going with the flow. I have always liked technology and hardware. As a kid, I liked breaking appliances (radio, remote controller) into smaller parts to see what was inside. Following this muse, I got my bachelor's and master's degrees at Nazarbayev University, majoring in Robotics. But after graduation, I became a Data Scientist with little knowledge of machine learning and deep learning. 
        Looking back, I think that I was extremely lucky to get that job. I learned about deep learning and research. I even published some papers. But now, I am a Data Engineer.
      </p>
    </div>
  </div>
);


const About = () => (
  <div className="container">
    <h1 className="text-3xl font-bold mb-4">About Me</h1>
    <p className="text; text-align: justify; width: 100%;">
    Hi, my name is Mukhamet Nurpeiissov. I am a data engineer with over five years of experience. 
    My journey in the tech industry has been driven by a passion for technology and a curiosity for 
    understanding how things work. I hold a bachelor's and master's degree in Robotics from Nazarbayev University, 
    where I developed a strong foundation in both hardware and software. Over the years, I have transitioned from 
    being a Data Scientist, where I gained valuable insights into machine learning and deep learning, 
    to my current role as a Data Engineer. Throughout my career, I have honed my skills in data architecture, 
    ETL processes, and data pipeline optimization, consistently striving to transform raw data into actionable insights. 
    I am dedicated to leveraging my expertise to solve complex data challenges and drive innovation in the field.
    </p>
    <div className="links mt-4">
      <p>LinkedIn: <a href="https://www.linkedin.com/in/mukhamet-nurpeiissov-9b869b90/" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Mukhamet Nurpeiissov</a></p>
      <p>Email: <a href="mailto:mukhamet.nurpeiissov@gmail.com" className="text-blue-600 hover:text-blue-800">mukhamet.nurpeiissov@gmail.com</a></p>
      <p>Scholar: <a href="https://scholar.google.com/citations?user=hH9xIcAAAAAJ&hl=en&oi=ao" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Mukhamet Nurpeiissov</a></p>
      <p>Leetcode: <a href="https://leetcode.com/u/mnurpeiissov/" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">@mnurpeiissov</a></p>
      <p>Github: <a href="https://github.com/mnurpeiissov" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">@mnurpeiissov</a></p>
    </div>

    <h2 className="text-2xl font-semibold mt-6 mb-2">Work Experience</h2>
    <div className="timeline">
      <div className="timeline-item">
        <div className="timeline-content">
          <h3 className="text-xl font-bold">Data Engineer</h3>
          <h4 className="text-lg font-semibold">M42 Health, Onsite, Abu Dhabi</h4>
          <p className="text-sm">Nov 2023 - Present</p>
        </div>
      </div>
      <div className="timeline-item">
        <div className="timeline-content">
          <h3 className="text-xl font-bold">Data Engineer</h3>
          <h4 className="text-lg font-semibold">CEX.IO, Remote, London</h4>
          <p className="text-sm">Jun 2022 - Nov 2023</p>
        </div>
      </div>
      <div className="timeline-item">
        <div className="timeline-content">
          <h3 className="text-xl font-bold">Data Engineer</h3>
          <h4 className="text-lg font-semibold">MixRank, Remote, California</h4>
          <p className="text-sm">May 2021 - Jun 2022</p>
        </div>
      </div>
      <div className="timeline-item">
        <div className="timeline-content">
          <h3 className="text-xl font-bold">Data Scientist</h3>
          <h4 className="text-lg font-semibold">ISSAI, Onsite, Astana</h4>
          <p className="text-sm">Oct 2019 - Aug 2021</p>
        </div>
      </div>
    </div>
    <h2 className="text-2xl font-semibold mt-6 mb-2">Education</h2>
    <div className="timeline">
      <div className="timeline-item">
        <div className="timeline-content">
          <h3 className="text-xl font-bold">Master of Science in Robotics and Mechatronics</h3>
          <h4 className="text-lg font-semibold">Nazarbayev University</h4>
          <p className="text-sm">Graduation Year: 2019</p>
        </div>
      </div>
    </div>
    <div className="timeline">
      <div className="timeline-item">
        <div className="timeline-content">
          <h3 className="text-xl font-bold">Bachelor of Science in Robotics and Mechatronics</h3>
          <h4 className="text-lg font-semibold">Nazarbayev University</h4>
          <p className="text-sm">Graduation Year: 2017</p>
        </div>
      </div>
    </div>
  </div>
);


function App() {
  return (
    <Router>
      <div className="min-h-screen bg-[#F5F5F7]">
        <nav className="shadow-md mb-8">
          <ul className="flex justify-center space-x-6 p-4">
            <li><Link to="/" className="hover:text-blue-800 font-semibold">Home</Link></li>
            <li><Link to="/about" className="hover:text-blue-800 font-semibold">About</Link></li>
            <li><Link to="/projects" className="hover:text-blue-800 font-semibold">Projects</Link></li>
            <li><Link to="/blogs" className="hover:text-blue-800 font-semibold">Blogs</Link></li>
          </ul>
        </nav>
        <main className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogPost />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
